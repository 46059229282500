import React from "react"
import { Link, withPrefix } from "gatsby"

import API from "../../services/api"

export default class PostComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
    }
  }

  componentDidMount() {
    var self = this
    this.props.post["categories"].map(id => {
      API.getPostCategories(id).then(function(resp) {
        self.setState(prevState => ({
          categories: [...prevState.categories, resp["data"]["name"]],
        }))
      })
    })
  }

  render() {
    const { post, featured } = this.props
    const { categories } = this.state
    return (
      <div className={`col-sm-12 col-md-${featured ? "6" : "4"}`}>
        {post["better_featured_image"] && (
          <>
            <Link to={withPrefix(`/blog/post/${post["slug"]}`)}>
              <img
                src={
                  "https://blog.advanxhealth.com/wp-content/uploads/" +
                  post["better_featured_image"]["media_details"]["file"]
                }
                class="img-fluid blog-post"
                alt="Responsive image"
              />
            </Link>
          </>
        )}
        <div className="d-flex flex-row pt-3">
          {categories.map((category, index) => {
            return (
              <label
                className={
                  (index != 0 && "pl-4") +
                  " text-primary-pink font-weight-bold text-sm"
                }
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: category,
                  }}
                ></span>
              </label>
            )
          })}
        </div>
        <Link to={withPrefix(`/blog/post/${post["slug"]}`)}>
          <h4
            dangerouslySetInnerHTML={{
              __html: post["title"]["rendered"],
            }}
          ></h4>
          <div className="d-flex flex-row pt-3">
            <span
              dangerouslySetInnerHTML={{
                __html: post["excerpt"]["rendered"],
              }}
            ></span>
          </div>
        </Link>
      </div>
    )
  }
}
