import React from "react"
import { Link, withPrefix } from "gatsby"

import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import PostComponent from "../components/blogs/PostComponent"

import API from "../services/api"

export default class BlogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      categories: [3, 255, 1, 252, 251, 259, 195],
      fetched_categories: [],
    }
  }

  componentDidMount() {
    var self = this
    API.getBlogPost().then(function(resp) {
      self.setState({ posts: resp.data })
    })
    API.getPostCategories().then(resp => {
      self.setState({ fetched_categories: resp["data"] })
    })
  }

  render() {
    const { posts, fetched_categories } = this.state
    const filtered_posts = id => {
      return posts.filter(function(post) {
        return post["categories"].includes(id)
      })
    }

    const filtered_categories = fetched_categories.filter(function(fetched) {
      return fetched["count"] > 1
    })

    return (
      <Layout>
        <div className="blog-page">
          <HeadMeta metadata={pageData.metadata} />
          <ProgressBar />
          <section className="container">
            <h3 className="text-primary">Latest Stories</h3>
            <div className="row pt-4">
              {posts.slice(0, 2).map(item => {
                return <PostComponent post={item} featured={true} />
              })}
            </div>
          </section>
          <hr className="w-75" />
          <section className="py-5">
            {filtered_categories.map(category => {
              return (
                <div className="container">
                  <h3
                    className="text-primary"
                    dangerouslySetInnerHTML={{
                      __html: category["name"],
                    }}
                  ></h3>
                  <div className="row pt-4">
                    {filtered_posts(category["id"])
                      .slice(0, 6)
                      .map(item => {
                        return <PostComponent post={item} featured={false} />
                      })}
                  </div>
                </div>
              )
            })}
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Blog | Advanx Health",
    description:
      "Find the answers to some of the most frequently asked questions about Advanx Health's DNA tests and personalised services.",
    url: "https://www.advanxhealth.com/blog",
  },
}
